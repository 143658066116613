import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49a084fc = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _606b6c8a = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _342d0226 = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _63b34184 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _771c2307 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _81a6e6c0 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _388929a9 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _383c75f8 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _e502c028 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _f668257c = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _35c2ef8e = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _08c10bca = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _7bce783d = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _39243fdf = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _5c594359 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _262f72b9 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _293598c2 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _52f4235f = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _261f2fe1 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _18ca4064 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _742fb1e0 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _49a084fc,
    name: "kits"
  }, {
    path: "/lojas",
    component: _606b6c8a,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _342d0226,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _63b34184,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _771c2307,
    name: "motos"
  }, {
    path: "/reset",
    component: _81a6e6c0,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _388929a9,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _383c75f8,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _e502c028,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _f668257c,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _35c2ef8e,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _08c10bca,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _7bce783d,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _39243fdf,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _5c594359,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _262f72b9,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _293598c2,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _52f4235f,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _261f2fe1,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _18ca4064,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _742fb1e0,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
